import React, { useCallback, useEffect, useState } from 'react'
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { AxiosError } from 'axios'
import { Loader } from '@mantine/core'

import axios from 'src/api'

const adminCore = ({ component: Component, ...rest }) => {
  const [checkingAdmin, setCheckingAdmin] = useState(true)
  const [isAdmin, setIsAdmin] = useState(false)
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    axios.get('/user/admin')
      .then((res) => {
        setCheckingAdmin(false)
        setIsAdmin(true)
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 403) {
          // redirect to home page if not admin
          history.push('/')
        }
      })
  }, [location.pathname])

  if (checkingAdmin) {
    return <Loader />
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        isAdmin ? (rest.render?.(props) || <Component {...props} />) : <Redirect to='/' />
      )}
    />
  )
}

const mapStateToProps = (state) => ({ roles: state.auth.roles })

const AdminRoute = connect(mapStateToProps)(adminCore)

export default AdminRoute
