import { CLOUDFRONT_BASE } from './constants'

import jfzLogo1 from '../assets/course_cards/jfz1white.svg'
import jfzLogo2 from '../assets/course_cards/jfz2white.svg'
import jfzLogo3 from '../assets/course_cards/jfz3white.svg'
import jfzLogo4 from '../assets/course_cards/jfz4white.svg'
import jfzLogo5 from '../assets/course_cards/jfz5white.svg'
import jin5Logo from '../assets/course_cards/logo-fluent-in-five-white.svg'
// import jin5Logo from '../assets/course_cards/course_logo_japanese_in_5.svg'
import askLogo from '../assets/course_cards/course_logo_ask_a_teacher.svg'
import hitokotoLogo from '../assets/course_cards/course_logo_hito_koto.svg'
import dailyLogo from '../assets/course_cards/course_logo_japanese_daily.svg'
import jtmLogo from '../assets/course_cards/course_logo_japanese_topics_mania.svg'
import kanjiLogo from '../assets/course_cards/kanjifz1white.svg'
import kanji2Logo from '../assets/course_cards/kanjifz2white.svg'
import korean1Logo from '../assets/course_cards/logo_kfz_1.svg'
import korean2Logo from '../assets/course_cards/logo_kfz_2.svg'
import korean3Logo from '../assets/course_cards/logo_kfz_3.svg'

import color_jfzLogo1 from '../assets/course_cards/jfz1.svg'
import color_jfzLogo2 from '../assets/course_cards/color_jfz2.svg'
import color_jfzLogo3 from '../assets/course_cards/color_jfz3.svg'
import color_jfzLogo4 from '../assets/course_cards/color_jfz4.svg'
import color_jfzLogo5 from '../assets/course_cards/color_jfz5.svg'
import colorLogoKanji1 from '../assets/course_cards/color_kanjifz1.svg'
import colorLogoKanji2 from '../assets/course_cards/color_kanjifz2.svg'
import kansaiLogo from '../components/pages/kansai_from_zero.726636ce.png'

import char1 from '../assets/chibi/course_preview/course_stroke_tomoko.png'
import char2 from '../assets/chibi/course_preview/course_stroke_kenji.png'
import char3 from '../assets/chibi/course_preview/course_stroke_mari.png'
import char4 from '../assets/chibi/course_preview/course_stroke_ren.png'
import char5 from '../assets/chibi/course_preview/course_stroke_rika.png'
import char6 from '../assets/chibi/course_preview/course_stroke_nasumi_1.png'
import natsumi_panic from '../assets/chibi/jfz_chibi_natsumi_2.png'
import hitokotoChar from '../assets/chibi/kenji/chibi_new_kana.png'
import askChar from '../assets/chibi/natsumi/chibi_quiz.png'
import kanjiChar2 from '../assets/chibi/hazuma/chibi_course_hazuma.webp'
import jtmChar from '../assets/chibi/tomoko/chibi_confirmation.png'
import dailyChar from '../assets/chibi/ren/chibi_grammar.png'

import adult_charKanji from '../assets/characters/character_natsumi.webp'
import adult_charKanji2 from '../assets/characters/character_hazuma_stroke.webp'
import exclaim1 from '../assets/courses/overview/exclaim_1.svg'
import exclaim2 from '../assets/courses/overview/exclaim_2.svg'
import exclaim3 from '../assets/courses/overview/exclaim_3.svg'
import exclaim4 from '../assets/courses/overview/exclaim_4.svg'
import exclaim5 from '../assets/courses/overview/exclaim_5.svg'
import { CourseInfo } from 'src/types'

const adult_char1 = `${CLOUDFRONT_BASE}/images/characters/character_tomoko.webp`
const adult_char2 = `${CLOUDFRONT_BASE}/images/characters/kenji_normal_b.webp`
const adult_char3 = `${CLOUDFRONT_BASE}/images/characters/character_mari.webp`
const adult_char4 = `${CLOUDFRONT_BASE}/images/characters/ren_p_happy_b.webp`
const adult_char5 = `${CLOUDFRONT_BASE}/images/characters/rika_p_normal_b.webp`

const COURSE_INFO_BASE = {
  color: 'var(--jfz-blue)',
  alt_color_1: 'var(--jfz-blue)',
  alt_color_2: 'var(--sub-blue)',
  alt_color_3: '#67a6e7',
  alt_color_4: '#91cdfb',
  alt_color_5: '#8ebfe8',
  alt_color_6: '#e2f1ff',
  alt_color_7: '#60a2ef',
  header_color: '#152344',
  lesson_font_color: 'var(--jfz-blue)',
  gradient:
    'linear-gradient(180deg, rgba(173,193,224,1) 0%, rgba(194,208,231,1) 25%, rgba(231,236,244,1) 100%)', // for Courses Preview (/courses)
  lop_gradient: 'linear-gradient(#1176bd, #82afde)', // for Lesson Overview Page (/courses/:cid/:lid)
  zebra_stripes:
    'repeating-linear-gradient(135deg, #1d78be, #1d78be 3px, #1489dc 3px, #1489dc 6px)', // for popsicle sticks
  size: '180px', // for Courses Preview
  pos_x: '50%', // for Courses Preview
  pos_y: '0%', // for Courses Preview
  hover_pos_x: '20%', // for Courses Preview
  hover_pos_y: '0%', // for Courses Preview
  hover_size: '210px', // for Courses Preview
  course_exclaim: exclaim1,
  color_logo: undefined,
  logo: undefined,
  character: 'https://d32tv5vz8ua1sf.cloudfront.net/images/chibi/courses/natsumi_panic.png', // for Courses Preview
  overview_character: adult_char1, // for Course Overview
}

const COURSE_DATA = {
  'Japanese From Zero! 1': {
    color: '#1d78be',
    alt_color_1: '#1d78be',
    alt_color_2: '#88b4e0',
    alt_color_3: '#67a6e7',
    alt_color_4: '#91cdfb',
    alt_color_5: '#8ebfe8',
    alt_color_6: '#e2f1ff',
    alt_color_7: '#60a2ef',
    header_color: '#162448',
    lesson_font_color: '#1d78be',
    gradient: 'linear-gradient(0deg, #88b4e0 0%, #1d78be 100%)',
    lop_gradient: 'linear-gradient(#1176bd, #82afde)',
    zebra_stripes:
      'repeating-linear-gradient(135deg, #1d78be, #1d78be 3px, #1489dc 3px, #1489dc 6px)',
    size: '230px',
    pos_x: '50%',
    pos_y: '5%',
    hover_pos_x: '-8%',
    hover_pos_y: '0%',
    hover_size: '250px',
    course_exclaim: exclaim1,
    color_logo: color_jfzLogo1,
    logo: jfzLogo1,
    character: char1,
    // character: 'https://d32tv5vz8ua1sf.cloudfront.net/images/chibi/courses/tomoko_stroke.png',
    overview_character: adult_char1,
  },
  'Japanese From Zero! 2': {
    color: '#c6424a', // originally #b91620
    alt_color_1: '#e31e29',
    alt_color_2: '#ef6960',
    alt_color_3: '#ef6965',
    alt_color_4: '#ff9c99',
    alt_color_5: '#ff9985',
    alt_color_6: '#ffe2e2',
    alt_color_7: '#f05a5a',
    header_color: '#4E2022',
    lesson_font_color: '#c6424a', // originally #b91620
    gradient: 'linear-gradient(0deg, #ef6960 0%, #e31e29 100%)',
    lop_gradient: 'linear-gradient(#c14148, #ee6860)',
    zebra_stripes:
      'repeating-linear-gradient(135deg, #e31e29, #e31e29 3px, #c6424a 3px, #c6424a 6px)',
    size: '240px',
    pos_x: '30%',
    pos_y: '30%',
    hover_pos_x: '50%',
    hover_pos_y: '15%',
    hover_size: '260px',
    course_exclaim: exclaim2,
    color_logo: color_jfzLogo2,
    logo: jfzLogo2,
    character: char2,
    // character: 'https://d32tv5vz8ua1sf.cloudfront.net/images/chibi/courses/kenji_stroke.png',
    overview_character: adult_char2,
  },
  'Japanese From Zero! 3': {
    color: '#007537',
    alt_color_1: '#009c49',
    alt_color_2: '#74be72',
    alt_color_3: '#8fc168',
    alt_color_4: '#b3e18f',
    alt_color_5: '#92d180',
    alt_color_6: '#e2ffe2',
    alt_color_7: '#5ebd5e',
    header_color: '#204026',
    lesson_font_color: '#007537',
    gradient: 'linear-gradient(0deg, #74be72 0%, #009c49 100%)',
    lop_gradient: 'linear-gradient(#7db46d, #a6d063)',
    zebra_stripes:
      'repeating-linear-gradient(135deg, #009c49, #009c49 3px, #007537 3px, #007537 6px)',
    size: '125px',
    pos_x: '60%',
    pos_y: '0%',
    hover_pos_x: '50%',
    hover_pos_y: '50%',
    hover_size: '135px',
    course_exclaim: exclaim3,
    color_logo: color_jfzLogo3,
    logo: jfzLogo3,
    character: char3,
    // character: 'https://d32tv5vz8ua1sf.cloudfront.net/images/chibi/courses/mari_stroke.png',
    overview_character: adult_char3,
  },
  'Japanese From Zero! 4': {
    color: '#732775',
    alt_color_1: '#8a288c',
    alt_color_2: '#b772b3',
    alt_color_3: '#b572b3',
    alt_color_4: '#daa9d9',
    alt_color_5: '#baa5f3',
    alt_color_6: '#f2e2ff',
    alt_color_7: '#a56fa5',
    header_color: '#3D255B',
    lesson_font_color: '#732775',
    gradient: 'linear-gradient(0deg, #b772b3 0%, #8a288c 100%)',
    lop_gradient: 'linear-gradient(#783d7a, #b369b1)',
    zebra_stripes:
      'repeating-linear-gradient(135deg, #8a288c, #8a288c 3px, #732775 3px, #732775 6px)',
    size: '190px',
    pos_x: '50%',
    pos_y: '20%',
    hover_pos_x: '45%',
    hover_pos_y: '15%',
    hover_size: '210px',
    course_exclaim: exclaim4,
    color_logo: color_jfzLogo4,
    logo: jfzLogo4,
    character: char4,
    // character: 'https://d32tv5vz8ua1sf.cloudfront.net/images/chibi/courses/ren_stroke.png',
    overview_character: adult_char4,
  },
  'Japanese From Zero! 5': {
    color: '#ab1754',
    alt_color_1: '#be2363',
    alt_color_2: '#d76c8d',
    alt_color_3: '#d07fa1',
    alt_color_4: '#f59cc0',
    alt_color_5: '#f3a5bf',
    alt_color_6: '#ffe2ef',
    alt_color_7: '#d36b8a',
    header_color: '#58263B',
    lesson_font_color: '#ab1754',
    gradient: 'linear-gradient(0deg, #d76c8d 0%, #be2363 100%)',
    lop_gradient: 'linear-gradient(#ad235c, #d36b8a)',
    zebra_stripes:
      'repeating-linear-gradient(135deg, #be2363, #be2363 3px, #ab1754 3px, #ab1754 6px)',
    size: '170px',
    pos_x: '50%',
    pos_y: '15%',
    hover_pos_x: '47%',
    hover_pos_y: '15%',
    hover_size: '190px',
    course_exclaim: exclaim5,
    color_logo: color_jfzLogo5,
    logo: jfzLogo5,
    character: char5,
    // character: 'https://d32tv5vz8ua1sf.cloudfront.net/images/chibi/courses/rika_stroke.png',
    overview_character: adult_char5,
  },
  'Kansai Dialect From Zero!': {
    color: 'var(--jfz-blue)',
    alt_color_1: 'var(--jfz-blue)',
    alt_color_2: 'var(--sub-blue)',
    alt_color_3: '#67a6e7',
    alt_color_4: '#91cdfb',
    alt_color_5: '#8ebfe8',
    alt_color_6: '#e2f1ff',
    lesson_font_color: 'var(--jfz-blue)',
    gradient:
      'linear-gradient(180deg, rgba(173,193,224,1) 0%, rgba(194,208,231,1) 25%, rgba(231,236,244,1) 100%)',
    lop_gradient: 'linear-gradient(#1176bd, #82afde)',
    zebra_stripes:
      'repeating-linear-gradient(135deg, #1d78be, #1d78be 3px, #1489dc 3px, #1489dc 6px)',
    size: '180px',
    pos_x: '10%',
    pos_y: '10%',
    hover_pos_x: '50%',
    hover_pos_y: '20%',
    hover_size: '200px',
    course_exclaim: exclaim1,
    color_logo: kansaiLogo,
    logo: kansaiLogo,
    character: char6,
    // character: 'https://d32tv5vz8ua1sf.cloudfront.net/images/chibi/courses/natsumi_stroke.png',
    overview_character: adult_char1,
  },
  'Kanji From Zero! 1': {
    color: '#99B2CF',
    alt_color_1: '#7BAAE1',
    alt_color_2: '#B2CDEC',
    alt_color_3: '#AECEF2',
    alt_color_4: '#B3D7FB',
    alt_color_5: '#89ACCA',
    alt_color_6: '#E2F1FF',
    alt_color_7: '#95BBE7',
    header_color: '#223348',
    lesson_font_color: '#99B2CF',
    gradient: 'linear-gradient(0deg, #9DB1C9 0%, #E6E9FC 100%)',
    lop_gradient: 'linear-gradient(#87A0B6, #A1C7E7)',
    zebra_stripes:
      'repeating-linear-gradient(135deg, #7BAAE1, #7BAAE1 3px, #99B2CF 3px, #99B2CF 6px)',
    size: '190px',
    pos_x: '50%',
    pos_y: '20%',
    hover_pos_x: '45%',
    hover_pos_y: '15%',
    hover_size: '210px',
    course_exclaim: exclaim1,
    color_logo: color_jfzLogo1,
    logo: kanjiLogo,
    character: askChar,
    overview_character: adult_charKanji,
  },
  'Kanji From Zero! 2': {
    color: '#A86C89',
    alt_color_1: '#C8588E',
    alt_color_2: '#D297B4',
    alt_color_3: '#D894B5',
    alt_color_4: '#EAB4CE',
    alt_color_5: '#BB819D',
    alt_color_6: '#FFE5F1',
    alt_color_7: '#DE9ABB',
    header_color: '#5A2941',
    lesson_font_color: '#A86C89',
    gradient: 'linear-gradient(0deg, #BF7699 0%, #F9C2E3 100%)',
    lop_gradient: 'linear-gradient(#B26F8F, #E093B8)',
    zebra_stripes:
      'repeating-linear-gradient(135deg, #C8588E, #C8588E 3px, #A86C89 3px, #A86C89 6px)',
    size: '190px',
    pos_x: '50%',
    pos_y: '20%',
    hover_pos_x: '45%',
    hover_pos_y: '15%',
    hover_size: '210px',
    course_exclaim: exclaim1,
    color_logo: color_jfzLogo1,
    logo: kanji2Logo,
    character: kanjiChar2,
    overview_character: adult_charKanji2,
  },
  'Korean From Zero! 1': {
    color: 'var(--jfz-blue)',
    alt_color_1: 'var(--jfz-blue)',
    alt_color_2: 'var(--sub-blue)',
    alt_color_3: '#67a6e7',
    alt_color_4: '#91cdfb',
    alt_color_5: '#8ebfe8',
    alt_color_6: '#e2f1ff',
    lesson_font_color: 'var(--jfz-blue)',
    gradient:
      'linear-gradient(180deg, rgba(173,193,224,1) 0%, rgba(194,208,231,1) 25%, rgba(231,236,244,1) 100%)',
    lop_gradient: 'linear-gradient(#1176bd, #82afde)',
    zebra_stripes:
      'repeating-linear-gradient(135deg, #1d78be, #1d78be 3px, #1489dc 3px, #1489dc 6px)',
    size: '110px',
    pos_x: '50%',
    pos_y: '20px',
    hover_pos_x: '60%',
    hover_pos_y: '10px',
    hover_size: '170px',
    course_exclaim: exclaim1,
    color_logo: korean1Logo,
    logo: korean1Logo,
    character: 'https://d32tv5vz8ua1sf.cloudfront.net/images/chibi/courses/hanbok.png',
    overview_character: adult_char1,
  },
  'Korean From Zero! 2': {
    color: '#c6424a', // originally #b91620
    alt_color_1: '#e31e29',
    alt_color_2: '#ef6960',
    alt_color_3: '#ef6965',
    alt_color_4: '#ff9c99',
    alt_color_5: '#ff9985',
    alt_color_6: '#ffe2e2',
    lesson_font_color: '#c6424a', // originally #b91620
    gradient: 'linear-gradient(0deg, #ef6960 0%, #e31e29 100%)',
    lop_gradient: 'linear-gradient(#c14148, #ee6860)',
    zebra_stripes:
      'repeating-linear-gradient(135deg, #e31e29, #e31e29 3px, #c6424a 3px, #c6424a 6px)',
    size: '110px',
    pos_x: '50%',
    pos_y: '20px',
    hover_pos_x: '60%',
    hover_pos_y: '10px',
    hover_size: '170px',
    course_exclaim: exclaim2,
    color_logo: korean2Logo,
    logo: korean2Logo,
    character: 'https://d32tv5vz8ua1sf.cloudfront.net/images/chibi/courses/hanbok.png',
    overview_character: adult_char2,
  },
  'Korean From Zero! 3': {
    color: '#007537',
    alt_color_1: '#009c49',
    alt_color_2: '#74be72',
    alt_color_3: '#8fc168',
    alt_color_4: '#b3e18f',
    alt_color_5: '#92d180',
    alt_color_6: '#e2ffe2',
    lesson_font_color: '#007537',
    gradient: 'linear-gradient(0deg, #74be72 0%, #009c49 100%)',
    lop_gradient: 'linear-gradient(#7db46d, #a6d063)',
    zebra_stripes:
      'repeating-linear-gradient(135deg, #009c49, #009c49 3px, #007537 3px, #007537 6px)',
    size: '110px',
    pos_x: '50%',
    pos_y: '20px',
    hover_pos_x: '60%',
    hover_pos_y: '10px',
    hover_size: '170px',
    course_exclaim: exclaim3,
    color_logo: korean3Logo,
    logo: korean3Logo,
    character: 'https://d32tv5vz8ua1sf.cloudfront.net/images/chibi/courses/hanbok.png',
    overview_character: adult_char3,
  },
  'Fluent in 5!': {
    character: 'https://d32tv5vz8ua1sf.cloudfront.net/images/chibi/courses/chibi_new_phrases_right.png',
    size: '160px',
    hover_size: '190px',
    hover_pos_x: '40%',
    logo: jin5Logo,
    color: '#1d78be',
    alt_color_1: '#1d78be',
    alt_color_2: '#88b4e0',
    alt_color_3: '#67a6e7',
    alt_color_4: '#91cdfb',
    alt_color_5: '#8ebfe8',
    alt_color_6: '#e2f1ff',
    header_color: '#162448',
    lesson_font_color: '#1d78be',
    gradient: 'linear-gradient(0deg, #88b4e0 0%, #1d78be 100%)',
    lop_gradient: 'linear-gradient(#1176bd, #82afde)',
    zebra_stripes:
      'repeating-linear-gradient(135deg, #1d78be, #1d78be 3px, #1489dc 3px, #1489dc 6px)',
  },
  'Ask a Teacher!': {
    character: askChar,
    size: '160px',
    hover_size: '190px',
    hover_pos_x: '40%',
    logo: askLogo,
    color: '#c6424a', // originally #b91620
    alt_color_1: '#e31e29',
    alt_color_2: '#ef6960',
    alt_color_3: '#ef6965',
    alt_color_4: '#ff9c99',
    alt_color_5: '#ff9985',
    alt_color_6: '#ffe2e2',
    header_color: '#4E2022',
    lesson_font_color: '#c6424a', // originally #b91620
    gradient: 'linear-gradient(0deg, #ef6960 0%, #e31e29 100%)',
    lop_gradient: 'linear-gradient(#c14148, #ee6860)',
    zebra_stripes:
      'repeating-linear-gradient(135deg, #e31e29, #e31e29 3px, #c6424a 3px, #c6424a 6px)',
  },
  'Japanese Topics Mania!': {
    character: jtmChar,
    size: '160px',
    hover_size: '190px',
    hover_pos_x: '40%',
    logo: jtmLogo,
    color: '#ab1754',
    alt_color_1: '#be2363',
    alt_color_2: '#d76c8d',
    alt_color_3: '#d07fa1',
    alt_color_4: '#f59cc0',
    alt_color_5: '#f3a5bf',
    alt_color_6: '#ffe2ef',
    header_color: '#58263B',
    lesson_font_color: '#ab1754',
    gradient: 'linear-gradient(0deg, #d76c8d 0%, #be2363 100%)',
    lop_gradient: 'linear-gradient(#ad235c, #d36b8a)',
    zebra_stripes:
      'repeating-linear-gradient(135deg, #be2363, #be2363 3px, #ab1754 3px, #ab1754 6px)',
  },
  'Japanese Daily!': {
    character: dailyChar,
    size: '160px',
    hover_size: '190px',
    hover_pos_x: '40%',
    logo: dailyLogo,
    color: '#007537',
    alt_color_1: '#009c49',
    alt_color_2: '#74be72',
    alt_color_3: '#8fc168',
    alt_color_4: '#b3e18f',
    alt_color_5: '#92d180',
    alt_color_6: '#e2ffe2',
    header_color: '#204026',
    lesson_font_color: '#007537',
    gradient: 'linear-gradient(0deg, #74be72 0%, #009c49 100%)',
    lop_gradient: 'linear-gradient(#7db46d, #a6d063)',
    zebra_stripes:
      'repeating-linear-gradient(135deg, #009c49, #009c49 3px, #007537 3px, #007537 6px)',
  },
  Hitokoto: {
    character: hitokotoChar,
    size: '160px',
    hover_size: '190px',
    hover_pos_x: '40%',
    logo: hitokotoLogo,
    color: '#732775',
    alt_color_1: '#8a288c',
    alt_color_2: '#b772b3',
    alt_color_3: '#b572b3',
    alt_color_4: '#daa9d9',
    alt_color_5: '#baa5f3',
    alt_color_6: '#f2e2ff',
    header_color: '#3D255B',
    lesson_font_color: '#732775',
    gradient: 'linear-gradient(0deg, #b772b3 0%, #8a288c 100%)',
    lop_gradient: 'linear-gradient(#783d7a, #b369b1)',
    zebra_stripes:
      'repeating-linear-gradient(135deg, #8a288c, #8a288c 3px, #732775 3px, #732775 6px)',
  },
  'Japanese From Zero! 1 (v8)': {
    color: '#1d78be',
    alt_color_1: '#1d78be',
    alt_color_2: '#88b4e0',
    alt_color_3: '#67a6e7',
    alt_color_4: '#91cdfb',
    alt_color_5: '#8ebfe8',
    alt_color_6: '#e2f1ff',
    header_color: '#162448',
    lesson_font_color: '#1d78be',
    gradient: 'linear-gradient(0deg, #88b4e0 0%, #1d78be 100%)',
    lop_gradient: 'linear-gradient(#1176bd, #82afde)',
    zebra_stripes:
      'repeating-linear-gradient(135deg, #1d78be, #1d78be 3px, #1489dc 3px, #1489dc 6px)',
    size: '230px',
    pos_x: '50%',
    pos_y: '5%',
    hover_pos_x: '-8%',
    hover_pos_y: '0%',
    hover_size: '250px',
    course_exclaim: exclaim1,
    color_logo: color_jfzLogo1,
    logo: jfzLogo1,
    character: char1,
    // character: 'https://d32tv5vz8ua1sf.cloudfront.net/images/chibi/courses/tomoko_stroke.png',
    overview_character: adult_char1,
  },
  'Japanese From Zero! 2 (new)': {
    color: '#c6424a', // originally #b91620
    alt_color_1: '#e31e29',
    alt_color_2: '#ef6960',
    alt_color_3: '#ef6965',
    alt_color_4: '#ff9c99',
    alt_color_5: '#ff9985',
    alt_color_6: '#ffe2e2',
    header_color: '#4E2022',
    lesson_font_color: '#c6424a', // originally #b91620
    gradient: 'linear-gradient(0deg, #ef6960 0%, #e31e29 100%)',
    lop_gradient: 'linear-gradient(#c14148, #ee6860)',
    zebra_stripes:
      'repeating-linear-gradient(135deg, #e31e29, #e31e29 3px, #c6424a 3px, #c6424a 6px)',
    size: '240px',
    pos_x: '30%',
    pos_y: '30%',
    hover_pos_x: '50%',
    hover_pos_y: '15%',
    hover_size: '260px',
    course_exclaim: exclaim2,
    color_logo: color_jfzLogo2,
    logo: jfzLogo2,
    character: char2,
    // character: 'https://d32tv5vz8ua1sf.cloudfront.net/images/chibi/courses/kenji_stroke.png',
    overview_character: adult_char2,
  },
  'Japanese From Zero! 3 (new)': {
    color: '#007537',
    alt_color_1: '#009c49',
    alt_color_2: '#74be72',
    alt_color_3: '#8fc168',
    alt_color_4: '#b3e18f',
    alt_color_5: '#92d180',
    alt_color_6: '#e2ffe2',
    header_color: '#204026',
    lesson_font_color: '#007537',
    gradient: 'linear-gradient(0deg, #74be72 0%, #009c49 100%)',
    lop_gradient: 'linear-gradient(#7db46d, #a6d063)',
    zebra_stripes:
      'repeating-linear-gradient(135deg, #009c49, #009c49 3px, #007537 3px, #007537 6px)',
    size: '125px',
    pos_x: '60%',
    pos_y: '0%',
    hover_pos_x: '50%',
    hover_pos_y: '50%',
    hover_size: '135px',
    course_exclaim: exclaim3,
    color_logo: color_jfzLogo3,
    logo: jfzLogo3,
    character: char3,
    // character: 'https://d32tv5vz8ua1sf.cloudfront.net/images/chibi/courses/mari_stroke.png',
    overview_character: adult_char3,
  },
  'Japanese From Zero! 4 (new)': {
    color: '#732775',
    alt_color_1: '#8a288c',
    alt_color_2: '#b772b3',
    alt_color_3: '#b572b3',
    alt_color_4: '#daa9d9',
    alt_color_5: '#baa5f3',
    alt_color_6: '#f2e2ff',
    header_color: '#3D255B',
    lesson_font_color: '#732775',
    gradient: 'linear-gradient(0deg, #b772b3 0%, #8a288c 100%)',
    lop_gradient: 'linear-gradient(#783d7a, #b369b1)',
    zebra_stripes:
      'repeating-linear-gradient(135deg, #8a288c, #8a288c 3px, #732775 3px, #732775 6px)',
    size: '190px',
    pos_x: '50%',
    pos_y: '20%',
    hover_pos_x: '45%',
    hover_pos_y: '15%',
    hover_size: '210px',
    course_exclaim: exclaim4,
    color_logo: color_jfzLogo4,
    logo: jfzLogo4,
    character: char4,
    // character: 'https://d32tv5vz8ua1sf.cloudfront.net/images/chibi/courses/ren_stroke.png',
    overview_character: adult_char4,
  },
  'Japanese From Zero! 5 (new)': {
    color: '#ab1754',
    alt_color_1: '#be2363',
    alt_color_2: '#d76c8d',
    alt_color_3: '#d07fa1',
    alt_color_4: '#f59cc0',
    alt_color_5: '#f3a5bf',
    alt_color_6: '#ffe2ef',
    header_color: '#58263B',
    lesson_font_color: '#ab1754',
    gradient: 'linear-gradient(0deg, #d76c8d 0%, #be2363 100%)',
    lop_gradient: 'linear-gradient(#ad235c, #d36b8a)',
    zebra_stripes:
      'repeating-linear-gradient(135deg, #be2363, #be2363 3px, #ab1754 3px, #ab1754 6px)',
    size: '170px',
    pos_x: '50%',
    pos_y: '15%',
    hover_pos_x: '47%',
    hover_pos_y: '15%',
    hover_size: '190px',
    course_exclaim: exclaim5,
    color_logo: color_jfzLogo5,
    logo: jfzLogo5,
    character: char5,
    // character: 'https://d32tv5vz8ua1sf.cloudfront.net/images/chibi/courses/rika_stroke.png',
    overview_character: adult_char5,
  }
}

export const getTitle = (course_id: string) => {
  return {
    jfz1: 'Japanese From Zero! 1',
    jfz2: 'Japanese From Zero! 2',
    jfz3: 'Japanese From Zero! 3',
    jfz4: 'Japanese From Zero! 4',
    jfz5: 'Japanese From Zero! 5',
    test: 'Japanese From Zero! 1',
    kanji1: 'Kanji From Zero! 1',
    kanji2: 'Kanji From Zero! 2',
    kanji3: 'Kanji From Zero! 3',
    kansai: 'Kansai Dialect From Zero!',
    jin5: 'Fluent in 5!',
    daily: 'Japanese Daily!',
    ask: 'Ask a Teacher!',
    hitokoto: 'Hitokoto',
    jtm: 'Japanese Topics Mania!',
    japanese1: 'Japanese From Zero! 1 (v8)',
    japanese2: 'Japanese From Zero! 2 (new)',
    japanese3: 'Japanese From Zero! 3 (new)',
    japanese4: 'Japanese From Zero! 4 (new)',
    japanese5: 'Japanese From Zero! 5 (new)',
    kfz1: 'Korean From Zero! 1',
    kfz2: 'Korean From Zero! 2',
    kfz3: 'Korean From Zero! 3',
    spanish1: 'Spanish From Zero! 1',
  }[course_id]
}

export const getShortTitle = (course_name: string) => {
  // for cases where the long title is available but we need the shortened version
  return {
    'Japanese From Zero! 1': 'jfz1',
    'Japanese From Zero! 2': 'jfz2',
    'Japanese From Zero! 3': 'jfz3',
    'Japanese From Zero! 4': 'jfz4',
    'Japanese From Zero! 5': 'jfz5',
    'Kansai Dialect From Zero!': 'kansai',
    'Fluent in 5!': 'jin5',
    'Japanese Daily!': 'daily',
    'Ask a Teacher!': 'ask',
    Hitokoto: 'hitokoto',
    'Japanese Topics Mania!': 'jtm',
    'Japanese From Zero! 1 (v8)': 'japanese1',
    'Japanese From Zero! 2 (new)': 'japanese2',
    'Japanese From Zero! 3 (new)': 'japanese3',
    'Japanese From Zero! 4 (new)': 'japanese4',
    'Japanese From Zero! 5 (new)': 'japanese5',
    'Korean From Zero! 1': 'kfz1',
    'Korean From Zero! 2': 'kfz2',
    'Korean From Zero! 3': 'kfz3',
    'Spanish From Zero! 1': 'spanish1',
    'Kanji From Zero! 1': 'kanji1',
    'Kanji From Zero! 2': 'kanji2',
  }[course_name]
}

const getCourseInfo = (title: string): CourseInfo => {
  if (COURSE_DATA[title]) return { ...COURSE_INFO_BASE, ...COURSE_DATA[title] }

  const shortTitle = getShortTitle(title)
  if (COURSE_DATA[shortTitle]) return { ...COURSE_INFO_BASE, ...COURSE_DATA[shortTitle] }

  return COURSE_INFO_BASE
}

export default getCourseInfo
