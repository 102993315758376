import styled from '@emotion/styled'
import { CLOUDFRONT_BASE } from 'src/util/constants'

const banner = `${CLOUDFRONT_BASE}/images/banners/new_years_2024/campaign-banner-account-settings.webp`

const NewYearsBanner = styled.div`
  height: 65px;
  max-width: 500px;
  background-image: url(${banner});
  background-position: center;
  background-size: cover;
  margin: 16px auto;

  @media (max-width: 535px) { height: 50px; }
  @media (max-width: 450px) { height: 40px; }
  @media (max-width: 380px) { background-position: 0%; }
`

export default NewYearsBanner
