// hook for calling logout on the server and resetting the redux state
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import useAPI from 'src/hooks/useAPI'

const useLogout = () => {
  const dispatch = useDispatch()
  const [res, loading, req] = useAPI('/user/logout', 'POST')

  useEffect(() => {
    if (!res) return
    dispatch({ type: 'SIGN_OUT' })
  }, [res])

  return {
    loading, logout: req
  }
}

export default useLogout
