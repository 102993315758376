import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import axios from 'src/api'

import { PayPalButton } from 'react-paypal-button-v2'

import { SubscriptionContext } from 'src/contexts/SubscriptionContext'

const MockedPayPalButton = () => <div>PayPal Button</div>

const usePayPal = ({
  onSuccess = () => null
}) => {
  const { chosenPlanDetails, affiliate, campaigns } = useContext(SubscriptionContext)
  const detailsRef = useRef(chosenPlanDetails)
  const [payPalError, setPayPalError] = useState<string | null>(null)

  useEffect(() => {
    detailsRef.current = chosenPlanDetails
  }, [chosenPlanDetails])

  // SANDBOX TEST PERSONAL ACCOUNT CREDS (not a security risk cuz fake creds)
  // email: sb-cvye58570345@personal.example.com
  // password: sO)(fx)4
  const paypalSubscribe = useCallback((_data, actions) => {
    console.log('paypalSubscribe', detailsRef.current)
    return actions.subscription.create({ plan_id: detailsRef.current?.paypal })
  }, [detailsRef.current, chosenPlanDetails, campaigns])

  /*
   * NOTE: Don't forget to change the webhook ID for localhost if you change ngrok!
   */
  const paypalOnApprove = useCallback((data) => {
    const { planId } = detailsRef.current || {}
    const campaign = campaigns?.[0]

    axios.post('/subscriptions/create_paypal', {
      id: data.subscriptionID, plan: planId, affiliate, campaign
    })
      .then(() => onSuccess())
      .catch(() => null)
  }, [detailsRef.current, chosenPlanDetails, campaigns])

  const paypalOnError = (data) => {
    setPayPalError(
      'There was an error processing your payment; please contact support@fromzero.com or try another payment method!'
    )
  }

  const paypalOnCancel = (data) => {
    setPayPalError('You cancelled your payment; please try again!')
  }

  // need cypress check because in testing this throws an error when loading paypal js
  const paypalButton = useMemo(() => (window as any).Cypress
    ? <MockedPayPalButton /> : (
      <PayPalButton
        currency='USD'
        createSubscription={paypalSubscribe}
        onApprove={paypalOnApprove}
        catchError={paypalOnError}
        onCancel={paypalOnCancel}
        options={{
          clientId: process.env.REACT_APP_PAYPAL_CLIENT,
          vault: true,
          intent: 'subscription',
        }}
        style={{
          shape: 'rect',
          color: 'gold',
          layout: 'vertical',
          label: 'subscribe',
          tagline: false,
        }}
      />
    ), [])

  return { paypalButton, payPalError }
}

export default usePayPal
