import { useCallback, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'src/api'

import { SubscriptionContext } from 'src/contexts/SubscriptionContext'
import useSubscribed from 'src/hooks/useSubscribed'

import AccountPageBanner from 'src/components/banners/AccountPageBanner'
import FlowHeader from './FlowHeader'
import AffiliateThankYou from './AffiliateThankYou'
import PlanSelection from './PlanSelection'
import PaymentOptions from './PaymentOptions'
import TomokoSubSuccess from './TomokoSubSuccess'

interface SubscriptionFlowProps {
  onSuccess?: () => void // only used in TomokoSubSuccess but wasn't actually used so I removed it, leaving this for ts errors -Mike
  location?: string
}

const SubscriptionFlow: React.FC<SubscriptionFlowProps> = ({ location }) => {
  const [subscriptionSuccess, setSubscriptionSuccess] = useState<boolean>(false)
  const { affiliate } = useContext(SubscriptionContext)
  const { isPremiumSubscribed } = useSubscribed()
  const urlLocation = useLocation()
  const isLandingPage = location === 'landing'
  const isAccountPage = urlLocation.pathname.includes('/account')

  const handleSubscriptionSuccess = useCallback(() => {
    setSubscriptionSuccess(true)

    // why is this initial login? -Mike
    axios.post('/user/initial_login', { sub: true, location: urlLocation.pathname })
      .then((res) => {
        if (res.data.success) {
          // nothin
        }
      })
  }, [urlLocation])

  if (isPremiumSubscribed && !subscriptionSuccess) {
    return <>
      <p>Looks like you&apos;re already subscribed! :) Thank you for your support!</p>
      <p>If you&apos;re seeing this message in error, please use our bug report form at the bottom of the page and let us know!</p>
    </>
  }

  return (
    <div data-cy='subscription-flow' style={isLandingPage ? { marginTop: '40px' } : { zIndex: 5 }}>
      {!isLandingPage && <>
        {isAccountPage && <AccountPageBanner />}
        <FlowHeader location={location} />
        <AffiliateThankYou affiliate={affiliate} />
        <PlanSelection />
      </>}
      <PaymentOptions onSuccess={handleSubscriptionSuccess} subscriptionSuccess={subscriptionSuccess} />
      <TomokoSubSuccess showing={subscriptionSuccess} />
    </div>
  )
}

export default SubscriptionFlow
