import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from 'src/configureStore'

import usePlan from 'src/hooks/subscriptions/usePlan'
import usePromos from 'src/hooks/subscriptions/usePromos'
import { PLAN_CHOICE } from 'src/hooks/subscriptions/usePricing'

type PlanDetails = {
  price: string
  priceId: string
  stripeId: string
  planName: string
  paypal: string
  interval: string
  planId: string
}

export interface SubscriptionContextValue {
  grandfatherPlan: string
  chosenPlan: PLAN_CHOICE
  price: string
  onPlanChosen: (plan: 'premium-monthly' | 'premium-annual') => void
  chosenPlanDetails: PlanDetails
  affiliate: any
  campaigns: any
  isNewYearsSale: boolean
  grandfather: any
  promos: any
  basePrice: string
  affiliate_campaign: any
}

const SubscriptionContext = createContext<SubscriptionContextValue>({
  grandfatherPlan: '',
  chosenPlan: 'premium-monthly',
  price: '',
  basePrice: '',
  onPlanChosen: () => null,
  affiliate: {},
  campaigns: {},
  isNewYearsSale: false,
  grandfather: {},
  promos: {},
  chosenPlanDetails: {
    price: '',
    priceId: '',
    stripeId: '',
    planName: '',
    paypal: '',
    interval: '',
    planId: ''
  },
  affiliate_campaign: {}
})

const SubscriptionProvider: React.FC = ({ children }) => {
  const { _id } = useSelector((state: RootState) => state.auth)
  // if they selected a plan on registration, set that as the active plan
  const planSelection = useSelector((state: RootState) => state.auth.planSelectionAtSignup === 'premium-annual' ? 'premium-annual' : 'premium-monthly')
  const [chosenPlan, setChosenPlan] = useState<PLAN_CHOICE>('premium-annual')
  const { affiliate, campaigns, isNewYearsSale, grandfather, promos, affiliate_campaign } = usePromos()
  const chosenPlanDetails = usePlan(chosenPlan, { affiliate, grandfather, isNewYearsSale, campaigns, promos })

  return (
    <SubscriptionContext.Provider value={{
      grandfatherPlan: '',
      chosenPlan,
      price: chosenPlanDetails?.price,
      affiliate,
      campaigns,
      isNewYearsSale,
      grandfather,
      promos,
      chosenPlanDetails,
      onPlanChosen: setChosenPlan,
      basePrice: chosenPlanDetails?.base,
      affiliate_campaign
    }}>
      {children}
    </SubscriptionContext.Provider>
  )
}

export { SubscriptionContext, SubscriptionProvider }
