/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useRef, useState } from 'react'
import PubSub from 'pubsub-js'

// import WidgetWrapper from '../../error_boundary/WidgetWrapper'
import SoundIcon from '../sound_icon/SoundIcon'

import { AudioContext } from 'src/contexts/AudioContext'

import useStaffRoles from '../../../hooks/useStaffRoles'
import StudyModePlaceholder from './StudyModePlaceholder'
import MarkdownBlock from 'src/components/MarkdownRenderer'
import ReactLinkify from 'react-linkify'

import { FormattingContext, FormattingProvider } from 'src/contexts/FormattingContext'

// interface SoundRendererProps {
//     text: string | string[]
//     playingNum?: number
//     num?: number
//     ping: () => null
// }

const getStylesFromFormat = (format) => {
  const styleObj = {}
  let styleToRegister = ''

  if (format?.bold) {
    styleObj.fontWeight = 'bold'
    styleToRegister += 'b'
  }
  if (format?.italic) {
    styleObj.fontStyle = 'italic'
    styleToRegister += 'i'
  }
  if (format?.underline) {
    styleObj.textDecoration = 'underline'
    styleToRegister += 'u'
  }
  if (format?.color) {
    styleObj.color = format.color
    styleToRegister += `c<${format.color}>`
  }
  if (format?.highlight) {
    styleObj.backgroundColor = format.highlight
    styleToRegister += `h<${format.highlight}>`
  }
  if (format?.strikethrough) {
    if (styleObj.textDecoration) {
      styleObj.textDecoration += ' line-through'
    } else {
      styleObj.textDecoration = 'line-through'
    }
  }

  return { styleObj, styleToRegister }
}

// markdown URL regex, e.g., [Click me!](https://www.google.com)
const urlRegex = /\[(.+)\]\((.+)\)/
/* eslint-disable */
const urlRegex2 = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
/* eslint-enable */

const kpRegex = /(\S+|`(.+)`)\(([⺀-⻳]|[一-鿿]|[豈-\uFB00])\)/g
const KanjiPartRenderer = ({ content }) => {
  if (!kpRegex.test(content)) {
    // console.log('kanji part renderer no regex test', content)
    return <ReactLinkify>{content}</ReactLinkify>
  }

  // ex: This `upside-down baby`(﫳) is my flesh(⺼) which I choose to "RAISE".
  // paren-separated kanji parts should get rendered centered above the text they
  // are directly in front of that is part of the capture groups in the regex
  const splits = content.split(kpRegex)

  const rsplits = splits.map((part, i) => {
    if (i === splits.length - 1) {
      // this part will be a string that includes a word in quotes
      // bold just the word in quotes
      const quoteRegex = /"(.+)"/
      const quoteMatch = quoteRegex.exec(part)
      if (quoteMatch) {
        const quote = quoteMatch[1]
        const quoteIndex = part.indexOf(quote)
        const beforeQuote = part.slice(0, quoteIndex)
        const afterQuote = part.slice(quoteIndex + quote.length)

        return (
          <>
            {beforeQuote}
            <span style={{ fontWeight: 'bold' }}>{quote}</span>
            {afterQuote}
          </>
        )
      }

      return part
    }
    if (i % 4 === 0) return part

    if (i % 4 === 3) {
      const kanjiPart = splits[i - 1] ? splits[i - 1] : splits[i - 2]
      const furigana = part

      return (
        <ruby key={i}>
          <rb style={{ textDecoration: 'underline' }}>{kanjiPart}</rb>
          <rt style={{ fontSize: '1.5rem', fontFamily: 'YesJapan Gothic' }}>{furigana}</rt>
        </ruby>
      )
    }
  })

  return rsplits
}

const FormatRenderer = ({ content, formatMarkerIndex }) => {
  const { markerStackIndex, registerIndex } = useContext(FormattingContext)

  // console.log('content', content)
  if (typeof content === 'string') return <ReactLinkify><KanjiPartRenderer content={content} /></ReactLinkify>
  if (!content) return ''
  const { text, format } = content
  const { styleObj, styleToRegister } = getStylesFromFormat(format)

  const urlMarkdownFound = urlRegex.exec(text)
  const splits = text?.split(urlRegex)

  if (formatMarkerIndex >= 0 && !markerStackIndex[formatMarkerIndex] && styleToRegister) {
    registerIndex(formatMarkerIndex, styleToRegister)
  }

  // replace the URL in the splits array with an anchor tag and remove the entry before it
  if (urlMarkdownFound) {
    // console.log(text.split(urlRegex))
    // eslint-disable-next-line array-callback-return
    return text.split(urlRegex).map((part, i) => {
      // eslint-disable-next-line curly
      if (i % 3 === 0) {
        // console.log('react linkify over kanji part renderer', part)
        return (
          <ReactLinkify>
            <span style={styleObj}><KanjiPartRenderer content={part} /></span>
          </ReactLinkify>
        )
      }
      // eslint-disable-next-line curly
      if (i % 3 === 2) return (
        <a
          style={{ fontWeight: 'bold' }}
          key={i}
          href={splits[i]}
          target='_blank'
          rel='noopener noreferrer'
        >
          {splits[i - 1]}
        </a>
      )
    })
  }

  const startsWithLeadingSpacePeriod = text?.match(/^ \./)

  // add margin-left: -5px to styleObj in new obj if startsWithLeadingSpacePeriod
  // this is a hack to fix the issue of the period being too far away from the previous character
  // when the previous character is a korean char that has a trailing space + period
  if (startsWithLeadingSpacePeriod) {
    styleObj.marginLeft = '-5px'
  }
  return <ReactLinkify>
    <span style={styleObj}>
      {content?.text?.replace(/^ \./, '.') || content || ''}
    </span>
  </ReactLinkify>
}

const titleize = (text) => {
  const sectionNumberRegex = /^[a-z0-9]*-[0-9]*.|^[a-z0-9]*:|^Group [a-z0-9]*:/i
  return (
    <>
      {text?.match(sectionNumberRegex) ? (
        <>
          <span className='yellow'>
            {text.match(sectionNumberRegex)}
          </span>
          {text.split(sectionNumberRegex)[1]}
        </>
      ) : (
        <FormatRenderer content={text} />
      )}
    </>
  )
}

// this can either be a text or a list of strings and react elements
const urlify = (text, formatMarkerIndex = -1, format = {}) => {
  const items = Array.isArray(text) ? text : [text]

  return items.map((item, idx) => {
    if (typeof item !== 'string') return item

    const urlMarkdownFound = urlRegex.exec(item)
    const splits = item?.split(urlRegex)

    // replace the URL in the splits array with an anchor tag and remove the entry before it
    if (urlMarkdownFound) {
      // eslint-disable-next-line array-callback-return
      return item.split(urlRegex).map((part, i) => {
        if (i % 3 === 0) return <FormatRenderer formatMarkerIndex={formatMarkerIndex} content={part} />
        // eslint-disable-next-line curly
        if (i % 3 === 2) return (
          <a
            style={{ fontWeight: 'bold' }}
            key={i}
            href={splits[i]}
            target='_blank'
            rel='noopener noreferrer'
          >
            {splits[i - 1]}
          </a>
        )
      })
    }

    return <FormatRenderer key={`${item}-${idx}`} formatMarkerIndex={formatMarkerIndex} content={item} />
  })
}

// detects img tags in text and replaces them with img elements
const imgify = (text) => {
  const imgRegex = /<img src="(.+)"\s*\/*>/g

  if (!imgRegex.test(text)) return text

  const splits = text.split(imgRegex)

  return splits.map((part, i) => {
    if (i % 2 === 0) return part

    return <img key={`img-${part}-${i}`} src={part} alt='' />
  })
}

const SoundRenderer = (props) => {
  const { placeholder, wrapAtIcon, resizeAfter, contextAudio } = props
  const { audio, setAudioSrc, clickTriggered, setClickTriggered, paWidget, setPAWidget } = useContext(AudioContext)

  const [text, setText] = useState(
    typeof props.text === 'object' ? props.text?.filter((e) => e !== '') : props.text
  )

  const [numObjects, setNumObjects] = useState(
    typeof text === 'object' && text?.filter((e) => typeof e === 'object' && e?.record).length
  )

  // for nested sounds in table cells etc
  const [internalPlaying, setInternalPlaying] = useState(-1)
  const internalRef = useRef(internalPlaying)
  const [isStaffRole] = useStaffRoles()

  useEffect(() => {
    if (clickTriggered) setInternalPlaying(-1)
  }, [clickTriggered])

  useEffect(() => {
    // console.log('playing num', props.playingNum, internalPlaying, text?.length, actualNumObjects)
    // multiple sounds within this renderer to play
    if (
      props.num !== undefined
      && numObjects > 1
      && props.num === props.playingNum
      && internalRef.current === -1
    ) {
      // console.log('setting internal playing; multiple sounds')
      setInternalPlaying(0)
    }
    if (props.playingNum === -1) setInternalPlaying(-1)
  }, [props.playingNum, numObjects])

  useEffect(() => {
    // console.log('internal playing effect', internalPlaying)
    internalRef.current = internalPlaying
    if (internalPlaying >= 0 && !text?.[internalPlaying]?.record) {
      // console.log('progressing internal playing', text?.[internalPlaying])
      setInternalPlaying(internalPlaying + 1)
    }
    if (internalPlaying === text?.length) {
      setInternalPlaying(-1)
      props.ping(internalPlaying)
    }
  }, [internalPlaying])

  const internalPing = (num) => {
    // console.log('internal ref current', internalRef.current)
    if (num === -1) {
      if (props.ping) props.ping(-1)
      setInternalPlaying(-1)
      return
    }

    if (internalRef.current !== -1) {
      setInternalPlaying(internalRef.current + 1)
    } else if (props.ping) {
      setTimeout(() => props.ping(num), 500)
    }
  }

  // playing num is normally just a straight number going down the widget, unless a row or cell etc has
  // multiple sounds, in which case it needs a generated index of the form <index>-<internal index>
  const playingNum = internalPlaying !== -1 ? `${props.num}-${internalPlaying}` : props.playingNum
  const onSound = numObjects > 1 && props.onSound
    ? (num, noIcon, src) => props.onSound(props.num, noIcon, src)
    : props.onSound

  /* eslint-disable no-nested-ternary */
  return (
    <FormattingProvider>
      <StudyModePlaceholder placeholder={placeholder} text={text} index={props.index}>
        {/* Debug line for displaying some play all variables in browser */}
        {/* <span>{internalPlaying}, {numObjects}, {playingNum} -- </span> */}
        {(typeof text === 'string' || !text) ? (!props.isTitle ? (urlify(imgify(text))) : (
          titleize(text)
        )) : text?.map((t, index) => {
          if (typeof t === 'string' && t.trim() === '.') {
            // react-markdown seems to be removing spaces
            // and causing SoundIcon to be tightly packed with surrounding text
            // (especially offensive when a period follows Japanese text)
            return <span key={index} style={{ ...props.styles }}>{t}</span>
          }

          return typeof t === 'string' ? (
            props.isTitle ? titleize(t) : (
              <span key={index} style={{ ...props.styles }}>
                {' '}{urlify(imgify(t))}{' '}
              </span>
            )
          ) : (
            t?.record ? (
              <>
                <SoundIcon
                  {...props}
                  {...t}
                  key={`sound-${t.record._id}-${index}`}
                  className="sound"
                  icon={!props.noIcon && !t.icon}
                  lineNum={index === 0 && props.lineNum}
                  onSound={onSound}
                  ping={internalPing}
                  num={numObjects > 1 ? `${props.num}-${index}` : props.num}
                  triggerRerender={internalPlaying}
                  playingNum={playingNum}
                  playing={numObjects > 1 && playingNum === `${props.num}-${index}`}
                  contextAudio={contextAudio}
                  formatMarkerIndex={index}
                />
                {t.record?.type === 'KoreanRecord' && <>&nbsp;</>}
              </>
            ) : (
              // <ReactLinkify>{urlify(t?.text || t, index, t?.format)}</ReactLinkify>
              <FormatRenderer formatMarkerIndex={index} content={t} />
            )
          )
        })}
      </StudyModePlaceholder>
    </FormattingProvider>
  )
  /* eslint-enable */
}

export default SoundRenderer
