import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { useDebouncedValue } from '@mantine/hooks'

import useAPI from './useAPI'

import { FromZeroAPIResponse } from 'src/types/api'
import { Widget } from 'src/types/mongo_types/course_widgets'
import { MasterRecord, Record } from 'src/types/mongo_types/mongo'

export interface CacheLanguageRecord extends Record {
  caches: Widget[],
  english: [MasterRecord],
  text?: string,
  children?: [string]
}

export interface CacheMasterRecord extends MasterRecord {
  caches: Widget[]
}

export interface AaTQuestion {
  _id: string,
  body: string,
  title: string
}

export interface AaTAnswer {
  _id: string,
  body: string,
}

export type DictionaryRecord = {
  _id: string,
  k_ele: [{ keb: string }]
  r_ele: [{ reb: string }]
  sense: { gloss: string, pos: string }
}

export type SearchResults = {
  jp: CacheLanguageRecord[],
  en: CacheMasterRecord[],
  aat: {
    questions: AaTQuestion[],
    answers: AaTAnswer[]
  },
  dict: DictionaryRecord[]
}

type SearchHook = [
  SearchResults,
  boolean,
  Dispatch<SetStateAction<string>>,
  any,
  boolean,
  Dispatch<SetStateAction<string>>,
  Dispatch<SetStateAction<number>>,
  string,
  string,
  number
]

interface SearchResponse extends FromZeroAPIResponse {
  results: SearchResults
}

interface CountsResponse extends FromZeroAPIResponse {
  counts: any
}

const useSearch = (searchType = 'sounds'): SearchHook => {
  const [searchText, setSearchText] = useState<string>('')
  const [results, setResults] = useState<SearchResults>()
  const [counts, setCounts] = useState({})
  const [category, setCategory] = useState(searchType || 'sounds')
  const [resCount, loadingCount, reqCount] = useAPI<CountsResponse>('/search', 'LAZYGET')
  const [page, setPage] = useState(1)
  const [resSearch, loadingSearch, reqSearch] = useAPI<SearchResponse>(`/search/${category}`.replace(/\/$/, ''), 'LAZYGET')
  const [debounced] = useDebouncedValue(searchText, 500)

  useEffect(() => {
    setPage(1)
  }, [category])

  useEffect(() => {
    console.log(resSearch?.results)
    setResults(resSearch?.results)
  }, [resSearch])

  useEffect(() => {
    // console.log(resCount?.counts)
    setCounts(resCount?.counts)
  }, [resCount])

  useEffect(() => {
    if (!debounced) return
    reqSearch({ params: { text: debounced, page } })
  }, [debounced, page, category])

  useEffect(() => {
    if (!debounced) return
    reqCount({ params: { text: debounced, page } })
  }, [debounced, category])

  return [results, loadingSearch, setSearchText, counts, loadingCount, setCategory, setPage, searchText, category, page]
}

export default useSearch
