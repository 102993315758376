import { css } from '@emotion/react'
import styled from '@emotion/styled'
import tw from 'twin.macro'

/*
 * This file is only to be used for *small* things that are repeated across
 * many areas of the app (common colors, sizes, etc)!
 *
 * To create more substantial styles or anything that is more tightly coupled
 * to your component, create a directory (named after the component itself)
 * with both the component file and the styles as a styles.ts file,  and import
 * that from your component :)
 */

export const darkTextColorCode = '#2e64bc'
export const offWhiteColorCode = '#fbfaff'

export const bookDepositoryColorCode = '#4c394e'

export const fontColor = css`color: ${darkTextColorCode};`
export const offWhiteBackground = css`background-color: ${offWhiteColorCode};`
export const offWhiteBackgroundGlobal = css`
  body {
    ${offWhiteBackground}
  } 
`

export const regFontSize = css`font-size: var(--reg-font-size);`

export const darkFont = css`
  color: var(--blue-gray);
  ${regFontSize}
`

export const coloredFont = ({ color }) => css`
  color: ${color};
  ${regFontSize}
`

export const blurCSS = ({ blur }) => css`
  filter: blur(${blur ? 6 : 0}px);
  transition: 1s filter linear;
  -webkit-transition: 1s -webkit-filter linear;
  -moz-transition: 1s -moz-filter linear;
  -ms-transition: 1s -ms-filter linear;
  -o-transition: 1s -o-filter linear;
  transition-duration: 0.1s;
  
  &:hover {
    filter: blur(0px);
  }
`

export const absoluteCenter = css`
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
`

export const noScrollBars = css`
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const ModalExitButton = styled.div`
  ${tw`tw-absolute tw-flex tw-items-center tw-justify-center tw-rounded-full tw-cursor-pointer tw-border-4 tw-border-white`}
  background-color: #f57504;
  top: -10px;
  right: -10px;
  width: 40px;
  height: 40px;
  z-index: 2;

  &:focus {
    outline: 2px solid #333;
  }

  &>div {
    ${tw`tw-relative tw-w-full tw-h-full tw-rounded-full tw-border-2 tw-border-black`}
  }

  i {
    ${tw`tw-relative tw-text-white`}
    top: 4px;
    left: 5px;
  }
`

interface FZButtonProps {
  color?: string
  backgroundColor?: string
  borderColor?: string
  fontWeight?: 'light' | 'regular' | 'bold'

  hoverColor?: string
  hoverBackgroundColor?: string
  hoverBorderColor?: string

  deleteButton?: boolean

  widthMaxContent?: boolean
  customStyles?: any // css helper function styles
}

export const FZButton = styled.button<FZButtonProps>`
  ${tw`tw-p-2 tw-flex tw-items-center tw-justify-center tw-rounded-lg tw-border-2 tw-cursor-pointer`}
  min-width: ${({ widthMaxContent }) => widthMaxContent ? 'max-content' : 'inherit'};
  font-family: 'Milliard Book', sans-serif;

  color: ${({ color }) => color ?? 'white'};
  font-weight: ${({ fontWeight }) => fontWeight || 'regular'};
  span {
    color: ${({ color }) => color ?? 'white'};
    font-weight: ${({ fontWeight }) => fontWeight || 'regular'};
  }
  border-color: ${({ borderColor }) => borderColor ?? 'var(--blue-gray)'};
  background-color: ${({ backgroundColor }) => backgroundColor ?? 'var(--blue-gray)'};

  i.icon {
    color: ${({ color }) => color ?? 'white'};
    transform: translate(3px, -3px);
  }

  ${({ deleteButton }) => deleteButton && css`
    background-color: #d9534f !important;
    border-color: #d9534f !important;
  `}

  :hover {
    color: ${({ hoverColor }) => hoverColor ?? 'white'};
    border-color: ${({ hoverBorderColor }) => hoverBorderColor ?? 'var(--blue-gray)'};
    background-color: ${({ hoverBackgroundColor }) => hoverBackgroundColor ?? 'var(--blue-gray)'};

    span {
      color: ${({ hoverColor }) => hoverColor ?? 'white'};
    }

    ${({ deleteButton }) => deleteButton && css`
      color: white;
      border-color: #d9635f !important;
      background-color: #d9635f !important;
      span { color: white; }
      i.icon { color: white; }
    `}
  }

  :disabled {
    cursor: default;
    opacity: 0.65;
    color: ${({ color }) => color ?? 'white'} !important;
    border-color: ${({ borderColor }) => borderColor ?? 'var(--blue-gray)'} !important;
    background-color: ${({ backgroundColor }) => backgroundColor ?? 'var(--blue-gray)'} !important;
    i { color: #ccc !important; }
    svg { fill: #ccc !important; }

    :hover {
      color: ${({ color }) => color ?? 'white'} !important;
      border-color: ${({ borderColor }) => borderColor ?? 'var(--blue-gray)'} !important;
      background-color: ${({ backgroundColor }) => backgroundColor ?? 'var(--blue-gray)'} !important;
      i { color: ${({ color }) => color ?? 'white'} !important; }
      svg { fill: ${({ color }) => color ?? 'white'} !important; }
    }
  }

  // for passing down custom css helper function block instead of setting props
  ${({ customStyles }) => customStyles ?? ''}
`

export const RoundedTextInput = styled.input`
  ${tw`tw-w-full tw-p-2 tw-rounded-lg`}
`

export const ColorSampleBox = styled.div<{ color: string }>`
  ${tw`tw-h-5 tw-w-5 tw-min-w-max tw-rounded-lg tw-shadow-md`}
  background-color: ${({ color }) => color};
  cursor: pointer;
`

interface ButtonIconProps {
  color?: string
  hoverColor?: string
  size?: string
}

export const FZButtonIcon = styled.div<ButtonIconProps>`
  i, svg { font-size: ${({ size }) => size || '1.3rem'}; }

  i {
    position: relative;
    bottom: 4px;
    color: ${({ color }) => color || 'var(--blue-gray)'};
  }
  svg { // react-heroicons
    ${tw`tw--ml-1 tw-mr-2 tw-h-5 tw-w-5`}
    fill: ${({ color }) => color || 'var(--blue-gray)'};
  }

  :hover {
    i {
      color: ${({ hoverColor }) => hoverColor || 'var(--blue-gray)'} !important;
    }
    svg {
      fill: ${({ hoverColor }) => hoverColor || 'var(--blue-gray)'} !important;
    }
  }

  @media (prefers-color-scheme: dark) {
    i {
      color: ${({ color }) => color || 'white'};
    }
    svg {
      fill: ${({ color }) => color || 'white'};
    }

    :hover {
      color: ${({ hoverColor }) => hoverColor || 'white'} !important;
      fill: ${({ hoverColor }) => hoverColor || 'white'} !important;
    }
  }
`

const screen_reader_only = tw`tw-sr-only`

export const SROnlySection = styled.section`${screen_reader_only}`
export const SROnlyDiv = styled.div`${screen_reader_only}`
export const SROnlyH1 = styled.h1`${screen_reader_only}`
export const SROnlyH2 = styled.h2`${screen_reader_only}`
export const SROnlyH3 = styled.h3`${screen_reader_only}`
export const SROnlyH4 = styled.h4`${screen_reader_only}`
export const SROnlyLabel = styled.label`${screen_reader_only}`

export const Textarea = styled.textarea`
  ${tw`
    tw-shadow-sm
    tw-block
    tw-w-full
    tw-border
    tw-border-gray-300
    tw-rounded-md
    tw-p-2
  `}
  font-size: 1.1rem;
  outline: none;
  margin-right: auto;

  :focus {
    border-color: var(--orange);
  }
`

export const PlayAll = styled.button`
  font-family: Lato, 'Helvetica Neue', Arial,Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  padding: .5rem;
  border-radius: 4px;
  width: 100px;
`

export const FlexFlowRoot = styled.div`
  ${tw`tw-flow-root`}
`

type FlexProps = {
  width?: number
  gap?: number
  center?: boolean
  justify?: 'between' | 'evenly' | 'around' | 'start' | 'center' | 'end'
  align?: 'start' | 'end' | 'center' | 'stretch' | 'baseline'
}

export const Flex = styled.div<FlexProps>`
  display: flex;
  width: ${({ width }) => width || 100}%; 
  ${({ gap }) => gap && css`gap: ${gap * 0.25}rem;`}
  ${({ center }) => center && css`align-items: center;`}
  ${({ justify }) => justify && css`justify-content: ${justify};`}
  ${({ align }) => align && css`align-items: ${align};`}
`

export const FlexCol = styled(Flex)`
  flex-direction: column;
`

export const FlexRow = styled(Flex)`
  flex-direction: row;
`

export const FlexList = styled.ul<FlexProps>`
  display: flex;
  width: ${({ width }) => width || 100}%; 
  ${({ gap }) => gap && css`gap: ${gap * 0.25}rem;`}
  ${({ center }) => center && css`align-items: center;`}
  ${({ justify }) => justify && css`justify-content: ${justify};`}
  ${({ align }) => align && css`align-items: ${align};`}
`

export const FlexColList = styled(FlexList)`
  flex-direction: column;
`

export const DividingDiv = styled.div`
  ${tw`tw-divide-y tw-divide-gray-200`}
`

export const MQ_XLViewport = styled.div`
  ${tw`tw-hidden xl:tw-block`}
  grid-column: span 3 / span 3;
`

export const MQ_MediumViewport = styled.div`
  ${tw`tw-mt-8 xl:tw-hidden`}
  grid-column: span 3 / span 3;
`

export const PaddingBottom4Div = styled.div`
  ${tw`tw-pb-4`}
`

export const PaddingTop4Div = styled.div`
  ${tw`tw-pt-4`}
`

export const PaddingY6Div = styled.div`
  ${tw`tw-py-6`}
`

export const MarginTop6Div = styled.div`
  ${tw`tw-mt-6`}
`
export const MarginBottom6Div = styled.div`
  ${tw`tw-mb-6`}
`

export const DynamicWidthFlexItem = styled.div`
  ${tw`tw-min-w-0 tw-flex-1`}
`

/**
 * Give numbers equal width
 * (useful for list elements where the numbers cause uneven sentence starts)
 */
export const TNumSpan = styled.span`
  font-feature-settings: 'tnum';
  font-variant-numeric: tabular-nums;
`

export const ColorSpan = styled.span<{ color?: string }>`
  color: ${({ color }) => color || 'var(--jfz-blue)'};
`

export const FZHeroIcon = styled.div<{
  color?: string
  hoverColor?: string
}>`
  svg {
    ${tw`tw-h-5 tw-w-5`}
    fill: ${({ color }) => color ?? 'var(--blue-gray)'};
  }
  :hover {
    svg {
      fill: ${({ hoverColor }) => hoverColor ?? 'var(--blue-gray)'};
    }
  }

  @media (prefers-color-scheme: dark) {
    svg {
      fill: ${({ color }) => color ?? 'white'};
    }
    :hover {
      svg {
        fill: ${({ hoverColor }) => hoverColor ?? 'white'};
      }
    }
  }
`
